<template lang="pug">
.game_log
  v-container.table_container
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 比賽紀錄
    v-row.table_row
      v-col#sticky-nav.table_col.table
        table
          thead.top_head
            sticky-column.bdr2(colspan="2", :offset="0")
            th(colspan="4")
            th(colspan="5")
            th.bdr(colspan="3") 投籃
            th.bdr(colspan="3") 兩分
            th.bdr(colspan="3") 三分
            th.bdr(colspan="3") 罰球
            th.bdr(colspan="2") 籃板
            th(colspan="4")
          thead.bottom_head
            sticky-column.bdl.bdr2(
              :style="{ minWidth: stickyColumnWidth + 'px', maxWidth: stickyColumnWidth + 'px' }",
              title="日期",
              :offset="0"
            )
            sticky-column.bdr2(
              colspan="1",
              title="對手",
              :offset="stickyColumnWidth"
            )
            th(colspan="1") 主/客
            th(colspan="1") 勝負
            th(colspan="1") 比分
            th(
              v-for="header in boxTableHeaders",
              :key="header.key",
              :class="{ bdr: header.border }"
            )
              span {{ header.name }}
          tbody
            tr(v-for="gameLog in gameLogs", :key="gameLog.game_id")
              sticky-column.bdl.bdr2.link(
                :style="{ minWidth: stickyColumnWidth + 'px', maxWidth: stickyColumnWidth + 'px' }",
                :title="gameLog.date",
                :offset="0",
                @click="gameClick(gameLog.game_id)"
              )
              sticky-column.bdr2(
                :title="gameLog.opp_team_name",
                :offset="stickyColumnWidth"
              )
              td {{ gameLog.is_home ? "主" : "客" }}
              td(
                :class="[gameLog.is_win ? 'text_secondary' : 'text_light_gray']"
              ) {{ gameLog.is_win ? "勝" : "負" }}
              td {{ gameLog.score }}
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, gameLog.total[header.key])",
                @click="dataClick(header.key, header.link, gameLog.game_id)"
              )
                span {{ gameLog.total[header.key] }}
  event-video-dialog(
    v-model="isDialogShow",
    :gameId="gameId",
    :playerId="playerId",
    :stat="stat"
  )
</template>

<script>
import { getPlayerGameLog } from "@/api/league";
import StickyColumn from "@/components/common/Table/StickyColumn";
import EventVideoDialog from "@/components/league/EventVideoDialog";

export default {
  name: "LeaguePlayerGameLog",
  components: {
    StickyColumn,
    EventVideoDialog,
  },
  data() {
    return {
      boxTableHeaders: [
        {
          name: "時間",
          key: "min",
          border: true,
          bold: false,
          link: false,
        },
        {
          name: "得分",
          key: "pts",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "籃板",
          key: "reb",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "助攻",
          key: "ast",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "抄截",
          key: "stl",
          border: false,
          bold: true,
          link: true,
        },
        {
          name: "阻攻",
          key: "blk",
          border: true,
          bold: true,
          link: true,
        },
        {
          name: "命中",
          key: "fgm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fga",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg2m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg2a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg2_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg3m",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fg3a",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "fg3_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "ftm",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "出手",
          key: "fta",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "命中率%",
          key: "ft_pct",
          border: true,
          bold: false,
        },
        {
          name: "進攻",
          key: "oreb",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "防守",
          key: "dreb",
          border: true,
          bold: false,
          link: true,
        },
        {
          name: "失誤",
          key: "tov",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "犯規",
          key: "pf",
          border: false,
          bold: false,
          link: true,
        },
        {
          name: "正負值",
          key: "plus_minus",
          border: false,
          bold: false,
          link: false,
        },
        {
          name: "效率值",
          key: "eff",
          border: true,
          bold: false,
          link: false,
        },
      ],
      stickyColumnWidth: 100,
      gameLogs: [],

      gameId: null,
      isDialogShow: false,
      stat: "",
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    seasonSegmentId() {
      return Number(this.$route.params.seasonSegmentId);
    },
    playerId() {
      return Number(this.$route.params.playerId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.playerId < 1)
        return;
      await this.getPlayerGameLogApi();
    },
    async getPlayerGameLogApi() {
      const params = {
        player_id: this.playerId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getPlayerGameLog(params);
      this.gameLogs = response.data.game_logs;
    },
    getDataClassObject(header, value) {
      let classObject = {};
      if (header.link && header.bold) classObject.link_deep = true;
      else if (header.link) classObject.link = true;
      else if (value <= 0) classObject.gray_out_heavy = true;
      classObject.bdr = header.border;
      return classObject;
    },
    dataClick(stat, link, gameId) {
      if (!link) return;
      this.stat = stat;
      this.gameId = gameId;
      this.isDialogShow = true;
    },
    gameClick(gameId) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          id: gameId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/player/game_log";
</style>
